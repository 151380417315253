<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardBody>
            <CButton color="primary" @click="create">
              <CIcon name="cil-plus" /> Create New Coupon</CButton
            >
            <br />
            <br />
            <br />
            <CAlert :color="alertType" v-if="message != null">
              {{ message }}</CAlert
            >
            <CDataTable
              hover
              :items="items"
              :fields="fields"
              :items-per-page="50"
              pagination
            >
              <template #name="{ item }">
                <td>
                  <strong>{{ item.name }}</strong>
                </td>
              </template>
              <template #companyName="{ item }">
                <td>
                  {{ item.companyName }}
                </td>
              </template>
              <template #validFrom="{ item }">
                <td>
                  {{ moment(new Date(item.validFrom)).format($dateFormat) }}
                </td>
              </template>
              <template #validTo="{ item }">
                <td>
                  {{ moment(new Date(item.validTo)).format($dateFormat) }}
                </td>
              </template>
              <template #EDIT="{ item }">
                <td>
                  <CButton color="primary" @click="edit(item.id)"
                    ><CIcon name="cil-pencil" /> Edit</CButton
                  >
                </td>
              </template>

              <template #DELETE="{ item }">
                <td>
                  <CButton
                    color="danger"
                    @click="
                      warningModal = true;
                      coupon = item;
                    "
                    ><CIcon name="cil-trash" /> Delete</CButton
                  >
                </td>
              </template>
            </CDataTable>
            <CModal
              v-model="coupon.id"
              title="Are you sure to delete selected coupon?"
              color="danger"
              :show.sync="warningModal"
              @update:show="closeModal"
            >
              <CRow>
                <CCol col="6">
                  <CInput label="Name" v-model="coupon.name" disabled />
                </CCol>
              </CRow>
            </CModal>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";

export default {
  name: "Coupons",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      // delete
      coupon: [],
      warningModal: false,
      // alert
      alertType: "danger",
      message: null,
      // list
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      items: [],
      fields: ["name",  "companyName", "validFrom", "validTo", "EDIT", "DELETE"],
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.coupon.id);
      }
    },
    edit(id) {
      const link = `coupons/create/${id.toString()}`;
      this.$router.push({ path: link });
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/Coupon/${id}`)
        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted coupon.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: `coupons/create/${this.emptyGuid}` });
    },

    getList() {
      let self = this;
      axios.get(`${this.$apiAdress}/v1/Coupon`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
